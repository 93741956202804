<div>

    <ul>
    <li><a href="#/home">home</a></li>

    <li><a href="#/position">position</a></li>

    <li><a href="#/track">track</a></li>

    <li><a href="#/counters">counters</a></li>
    </ul>
</div>